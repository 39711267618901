import React, { useEffect, useState } from 'react';
import './Table.css';

const basketData = [

]

export function Table() {
  const [soccerData, setSoccerData] = useState([]);

  useEffect( () =>{
    setSoccerData( [
      {
        name: "⚽ x2",
        description: "Se disputa la final a 2 goles, el que perdió es portero 🤣",
        owner: "Rodrigo",
        holded: 0
      },
      {
        name: "⚽ x3",
        description: "Se disputa la final a penales de 3 goles",
        owner: "Rodrigo",
        holded: 1
      },
      {
        name: "⚽ x4",
        description: "Se disputa la final a penales de 4 goles",
        owner: "Rodrigo",
        holded: 0
      },
      {
        name: "⚽ x5",
        description: "Se disputa la final a penales de 5 goles",
        owner: "Rodrigo",
        holded: 0

      },
      {
        name: "⚽ Shoot Outs",
        description: "Se disputa la final a penales de 4 goles",
        owner: "Rodrigo",
        holded: 1
      },
      {
        name: "🏀 x1",
        description: "",
        owner: "Rodrigo",
        holded: 0
      },
      {
        name: "🏀 x2",
        description: "",
        owner: "Eddy",
        holded: 0
      },
      {
        name: "🏀 x3",
        description: "",
        owner: "Eddy",
        holded: 0
      },
      {
        name: "🏀 x4",
        description: "",
        owner: "Eddy",
        holded: 0
      },
      {
        name: "🏀 x5",
        description: "",
        owner: "Eddy",
        holded: 0
      },
    ])
    console.log("types updated");
  }, []);

  
  const onDragStart = ( event, element) => {
    console.log( "Element", element);
    console.log( "Event Name", event.dataTransfer.getData('name'));
  }
  const onDragOver = (event, value) => {
    //console.log('DragOver', event);  
    event.preventDefault();
  };

  const onDrop = (event, index, newValue) => {
    soccerData[index].owner = newValue;
    setSoccerData([...soccerData]);
    console.log('Drop', soccerData);
    //console.log( "Drop Event",event.dataTransfer.getData('name'));
  };


  return (
    <table className="table">
      <thead>
        <tr>
            <th>Mundialitos</th>
            <th>
            <img className="circle-img" src="https://scontent.fmex16-1.fna.fbcdn.net/v/t1.0-9/104447027_10207260105138106_7271600048356801622_o.jpg?_nc_cat=104&ccb=2&_nc_sid=09cbfe&_nc_eui2=AeEjzMJjVdFSHOWeVh9NRllVxEYnIJEVMNPERicgkRUw018MYoADzSFJORPv1T0ncmo&_nc_ohc=7_fAZq0_lRsAX8kUNP6&_nc_ht=scontent.fmex16-1.fna&oh=642ae0bd4234916d484999f9faf4dd12&oe=601A37CB" alt="eddysaurio" />
            </th>
            <th>
              <img className="circle-img" src="https://scontent.fmex16-1.fna.fbcdn.net/v/t1.0-9/67101476_10215095999332581_6042519278912012288_o.jpg?_nc_cat=106&ccb=2&_nc_sid=09cbfe&_nc_eui2=AeGih-tIeF2eUlRVVlZL-mE1c_ZRzJIs6tdz9lHMkizq18saipVM-hFvBFa3ZjQiKQk&_nc_ohc=R-wXy8GZvYEAX-UTHRi&_nc_ht=scontent.fmex16-1.fna&oh=a1b41f7df7760f6654d1d602a4920c75&oe=601AF53D" alt="iwanosaurio" />
            </th>
            <th>
            <img  className="circle-img"src="https://scontent.fmex16-1.fna.fbcdn.net/v/t1.0-9/45887171_10217931398260392_3869231433037905920_n.jpg?_nc_cat=103&cb=846ca55b-311e05c7&ccb=2&_nc_sid=09cbfe&_nc_eui2=AeEUaOeMmXONaY6zOB0kn3Yp0IRfY70U8__QhF9jvRTz_1aKkOFU_zc8zA5oFEok6HA&_nc_ohc=r9ZBh6dYpoEAX_DVLsO&_nc_ht=scontent.fmex16-1.fna&oh=fc8dd1c2e0092eb96a888cf373394670&oe=6017EABF" alt="rodrigasaurio" />
            </th>            
            <th></th>
        </tr>
      </thead>
    <tbody>
    { soccerData.map((value, index) => {
       return (
         <tr key={index} >
          <td>{value.name}</td>        
          <td onDragOver={e => onDragOver(e, index)} onDrop={e => onDrop(e, index, "Eddy")}>
            { value.owner === "Eddy" && 
              <i className="fas fa-trophy fa-lg trophy" draggable onDragStart={e => onDragStart(e, value)}>
                { value.holded  > 0 &&
                  <span className="badge">1</span>
                }
              </i>
            }
          </td>
          <td onDragOver={e => onDragOver(e, index)} onDrop={e => onDrop(e, index, "Ivano")}>
            { value.owner === "Ivano" && 
              <i className="fas fa-trophy fa-lg trophy" draggable onDragStart={e => onDragStart(e, value)}>
                { value.holded  > 0 &&
                  <span className="badge">1</span>
                }
              </i>
            }
          </td>
          <td onDragOver={e => onDragOver(e, index)} onDrop={e => onDrop(e, index, "Rodrigo")}>
            { value.owner === "Rodrigo" && 
              <i className="fas fa-trophy fa-lg trophy" draggable onDragStart={e => onDragStart(e, value)}>
                { value.holded  > 0 &&
                  <span className="badge">1</span>
                }
              </i>
            }
          </td>
         <td></td>        
         <td></td>
       </tr>
       )
    })} 

    { basketData.map((value, index) => {
       return (
         <tr key={index}>
         <td>{value.name}</td>        
         <td>{ value.owner === "Eddy" && <i className="fas fa-trophy yellow"></i>}</td>
         <td>{ value.owner === "Ivano" && <i className="fas fa-trophy yellow"></i>}</td>
         <td>{ value.owner === "Rodrigo" && <i className="fas fa-trophy yellow"></i>}</td>
         <td></td>        
         <td></td>
       </tr>
       )
    })} 
     
    </tbody>
  </table>
  );
}