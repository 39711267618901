import { Table } from './components/Table'
import './App.css';

function App() {
  return (
    <div className="App">       
      <Table />
      <div className="background">
        <img src="https://i.pinimg.com/originals/da/43/4c/da434c410519ca9e9da314f6a2221ed5.jpg" alt="background"/> 
      </div>
    </div>
    

  );
}

export default App;
